import { replace } from "lodash";
import { Card, Col, Image, Row, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { tabdata } from "../Mint/TabContent/tabdata"
import { useState } from "react";


export function CollectionCards({ card, type, profile, nftID, external }) {
    let noAvatar = false
    let link = ""
    let nameAddr = profile.profile.findName ? profile.profile.findName : profile.profile.address

    function ShowVoucher({ image }) {
        const [show, setShow] = useState(false);

        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);

        return (
            <>
                <Button className="w-100" variant="dark" onClick={handleShow}>
                    View
                </Button>

                <Modal
                    show={show}
                    size="lg"
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <Image src={image} fluid></Image>
                        <div><p>{card.metadata.description}</p></div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
    let cardRole = card.role
    if (cardRole) {
        cardRole = cardRole.replace(/\s+/g, '').toLowerCase();
    }

    let image
    let thumbnail
    if (type === "nft") {
        if (card.mediaHash === "" || card.mediaHash === "DUMMY HASH" || card.mediaHash === "QmeM7CT9aLqakZUy51pBzf59NYe9yW7wS7Wa6rpDpo3aPN" || card.mediaHash === "QmRNPLnph6L7XB232DHiSBMvtRthivz6M8cmYreyyUKyjj" || card.mediaHash === "QmbpDjruX7dXw3Asy3FzvifwxjQuPcfVMfD5FEYEPtqHjJ" || card.mediaHash === "Qmberb1s1AYafWSoq8JsRw3PydVZx9ffLU6h1gqZHtc9To") {
            noAvatar = true
            link = "/reveal/avatar/" + card.id
            if (card.mediaHash === "" || card.mediaHash === "DUMMY HASH") {
                image = tabdata[cardRole].placeholder
                thumbnail = tabdata[cardRole].placeholder
            } else {
                image = "https://ipfs.neocollectibles.xyz/ipfs/" + card.mediaHash
                thumbnail = "https://ipfs.neocollectibles.xyz/ipfs/" + card.thumbnailHash
            }

        } else {
            image = "https://ipfs.neocollectibles.xyz/ipfs/" + card.mediaHash
            thumbnail = "https://ipfs.neocollectibles.xyz/ipfs/" + card.thumbnailHash
        }
    }
    if (type === "voucher") {
        link = "/reveal/voucher/" + card.id
        image = "https://ipfs.neocollectibles.xyz/ipfs/" + card.metadata.mediaHash
        thumbnail = "https://ipfs.neocollectibles.xyz/ipfs/" + card.metadata.thumbnailHash
    }

    return (
        <Col className="my-3 p-0" align="center">
            {type === "nft" &&
                <Card className="p-3 pb-4 CollectionCard">
                    <Image src={thumbnail} rounded={true} fluid={true}></Image>
                    <div className="Title mt-4 text-left" style={{ color: tabdata[cardRole].color }}>{card.role} #{card.edition}</div>
                    <div className="text-left">For {card.teamName}</div>
                    <Row className="mt-3">
                        {external !== "true" && noAvatar &&
                            <Col>
                                <Link to={link}>
                                    <Button style={{ textDecoration: "none" }} className="w-100" variant="dark" >Build</Button>
                                </Link>
                            </Col>
                        }
                        <Col className="">
                            {/* <ShowVoucher image={image} /> */}
                            <Link to={"/member/"+nameAddr+"/"+card.id}><Button variant="dark" className="w-100">View</Button></Link>
                        </Col>
                    </Row>
                </Card>
            }
            {type === "voucher" &&
                <Card className="p-3 pb-4 CollectionCard">
                    {card.metadata.mediaURI &&
                        <Image src="/pages/images/profile/Neo-Voucher.jpeg" rounded={true} fluid={true} />
                    }
                    {card.metadata.thumbnailHash !== "" &&
                        <Image src={thumbnail} rounded={true} fluid={true}></Image>}
                    <div className="Title mt-4 text-left" style={{ color: "black" }}>{card.metadata.name} #{card.id}</div>
                    <div className="text-left">Redeemable from 7th March 2022</div>
                    <Row className="mt-3">
                        {external !== "true" &&
                            <Col>
                                <Link to={link}>
                                    <Button style={{ textDecoration: "none" }} className="w-100" variant="dark">Redeem</Button>
                                </Link>
                            </Col>
                        }
                        <Col className="">
                            <ShowVoucher image={image} />
                        </Col>
                    </Row>
                    {/* <div className="mt-1 mb-2 text-left">for team #{card.founderUniqueNum}</div> */}
                </Card>
            }
        </Col>
    )
}