import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import NavHead from "./components/NavHead/NavHead";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home"
import "./config";
import Profile from './pages/Profile';
import ScrollToTop from './functions/ScrollToTop';
import RevealStandard from './pages/RevealStandard';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { CloseButton } from 'react-bootstrap';
import { LoadingNft } from './components/LoadingNft/LoadingNft';
import { useEffect, useState } from "react"
import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import {RecoilRoot} from "recoil"
import { scripts } from 'neo-flow-contracts'
import { useStateChanged } from './functions/DisabledState';
import MemberDetails from './pages/MemberDetails';

function App() { 
  
  const [profile, setProfile] = useState("first_init")
  const [user, setUser] = useState({ loggedIn: null })
  useEffect(() => fcl.currentUser().subscribe(setUser), [])

//   async function getProfile(address) {
//     const response = await fcl.send([
//         fcl.script(`import Profile from 0xa16ab1d0abde3625

//         //Check the status of a fin user
//         pub fun main(address: Address) :  Profile.UserProfile? {
//             return getAccount(address)
//                 .getCapability<&{Profile.Public}>(Profile.publicPath)
//                 .borrow()?.asProfile()
//         }`),
//         fcl.args([
//             fcl.arg(address, t.Address)
//         ]),
//     ])
//     const profileData = await fcl.decode(response);
//     setProfile(profileData)
// }
async function getProfile(address) {
  const response = await fcl.send([
      fcl.script(scripts.status),
      fcl.args([
          fcl.arg(address, t.Address)
      ]),
  ])
  const profileData = await fcl.decode(response);
  setProfile(profileData)
}

useEffect(() => {
    if (user.addr) {
        getProfile(user.addr)
    } else {
        setProfile(null)
    }
}, [user, useStateChanged()])

  return (
    <Router>
      <RecoilRoot>
      <div className="toastText">
        <Toaster toastOptions={{
          duration: Infinity,
          className: 'toastNotification'
        }}>
          {(t) => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <>
                  {icon}
                  {message}
                  {t.type !== 'loading' && (
                    <CloseButton onClick={() => toast.dismiss(t.id)}></CloseButton>
                  )}
                </>
              )}
            </ToastBar>
          )}
        </Toaster></div>
      <ScrollToTop />
      <NavHead />
      <Routes>
      
        <Route path ='/' element={<Home  />} />
        {/* <Route path ='/explore' element={<StartCollection />} /> */}
        <Route path ='/profile' element={<Profile profile={profile}/>} />
        {/* <Route path ='/neo-fest' element={<NeoFest  />} /> */}
        {/* <Route path ='/reveal/:id' element={<RevealStandard/>} /> */}
        <Route path ='/reveal/voucher/:id' element={<RevealStandard voucher={true} profile={profile} />} />
        <Route path ='/reveal/avatar/:id' element={<RevealStandard voucher={false} profile={profile} />} />
        {/* <Route path ='/reveal-founder' element={<RevealFounder  />} /> */}
        {/* <Route path ='/how-does-it-work' element={<HowDoesItWork />} /> */}
        {/* <Route path ='/txtests' element={<TxTests />} /> */}
        <Route path ='/loading/:voucherChoice' element={<LoadingNft />} />
        <Route path ='/loading/:voucherChoice/:amountOfVouchers' element={<LoadingNft />} />
        <Route path ='/member/:nameAdd/' element={<Profile profile={profile} external="true"/>} />
        <Route path ='/member/:nameAdd/voucher/:voucherID' element={<Profile profile={profile}/>} />
        <Route path ='/member/:nameAdd/:nftID' element={<Profile profile={profile} external="true" detail="true"/>} />
        {/* <Route path ='/member-details' element={<MemberDetails/>} /> */}

      </Routes>
      <Footer />
      </RecoilRoot>
    </Router>
  );
}

export default App;
