import { Container, Row, Col, Tabs, Tab, Image } from "react-bootstrap";
import { CollectionCards } from "./CollectionCards";

export function ProfileTabsSection({ profile, extProfile, external }) {
    let emptyCollection = false
    if (external === "true") {
        if (extProfile.members.length === 0 && extProfile.vouchers.length === 0) {
            emptyCollection = true
        }
    } else {
        if (profile.members.length === 0 && profile.vouchers.length === 0) {
            emptyCollection = true
        }
    }

    return (
        <Container id="ProfileTabSection" className="my-3">
            <Row>
                <Col>
                    <Tabs defaultActiveKey="collection" id="ProfileTabs" className="mb-3 profileTabs">
                        <Tab eventKey="collection" title="Collection">
                            <Container id="CollectionCards" className="my-5">
                                <Row id="Cards" className="">
                                    {
                                        external === "true" ?
                                            extProfile.members.length > 0 &&
                                            extProfile.members.map((card, i) =>
                                                <CollectionCards card={card} profile={extProfile} type="nft" key={i} external="true" />
                                            )
                                            :
                                            profile.members.length > 0 &&
                                            profile.members.map((card, i) =>
                                                <CollectionCards card={card} profile={profile} type="nft" key={i} />
                                            )
                                    }
                                    {
                                        external === "true" ?
                                            extProfile.vouchers.length > 0 &&
                                            extProfile.vouchers.map((card, i) =>
                                                <CollectionCards card={card} type="voucher" profile={extProfile} key={i} external="true" />
                                            )
                                            :
                                            profile.vouchers.length > 0 &&
                                            profile.vouchers.map((card, i) =>
                                                <CollectionCards card={card} type="voucher" profile={profile} key={i} />
                                            )
                                    }
                                    {
                                        emptyCollection &&
                                        <Col align="center"><h1>NOTHING TO SEE HERE!</h1><div>This collection is currently empty.</div><div><Image src="/pages/images/profile/no-collection.png" fluid /></div></Col>
                                    }
                                </Row>
                            </Container>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
            {/* {JSON.stringify(extProfile, null, 2)} */}
        </Container>
    )
}
