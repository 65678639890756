import { React } from "react";
import MemberKeyDetails from "../components/MemberDetails/MemberKeyDetails";

function MemberDetails({profile}) {

  return (
    <div>
      <MemberKeyDetails profile={profile}/>
    </div>
  );
}

export default MemberDetails