import { useEffect, useState } from "react";
import * as fcl from "@onflow/fcl";
import { Container } from "react-bootstrap";
import { Navbar, Nav, Image, Button } from 'react-bootstrap'
import { Link } from "../../../node_modules/react-router-dom";
import { AuthCluster } from "../../auth-cluster";
import './NavHead.css'

function NavHead() {
    const [user, setUser] = useState({ loggedIn: null })
    useEffect(() => fcl.currentUser().subscribe(setUser), [])

    return (
        <Navbar bg="white" expand="lg" collapseOnSelect>
            <Container className="py-3">
                <Navbar.Brand><Link to='/'><Image src="/pages/images/neo-collectibles-logo.webp" /></Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto" >
                        {/* <Nav.Link as={Link} to={"/"}><Image src='/pages/images/marketplace-coming-soon.webp' /></Nav.Link> */}
                        {/* <Nav.Link as={Link} to={"/explore"}>Explore</Nav.Link> */}
                        {/* <Nav.Link as={Link} to={"/neo-fest"}>NeoFest</Nav.Link> */}
                        {/* <Nav.Link as={Link} to={"/reveal-standard"}>Reveal</Nav.Link> */}
                        {/* <Nav.Link as={Link} to={"/reveal-founder"}>Reveal Founder</Nav.Link> */}
                        <Nav.Link as={Link} to={"/profile"}>Profile</Nav.Link>
                        <div className="d-lg-none d-sm-block p-2" align="center"><AuthCluster user={user} /></div>
                    </Nav>
                </Navbar.Collapse>
                <div className="float-end d-none d-lg-block" align="center"><AuthCluster user={user} /></div>
            </Container>
        </Navbar>
    )
}
export default NavHead