import { Button, Container, Row, Col, Form, Spinner } from "react-bootstrap"
import '../components/Profile/Profile.css'
import { ProfileHead } from "../components/Profile/ProfileHead";
import { handleCreateNeoCollections, handleCreateNeoUser } from "../functions/TxFunctions";
import { ProfileTabsSection } from "../components/Profile/ProfileTabs";
import { AuthCluster } from "../auth-cluster";
import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { scripts } from 'neo-flow-contracts'
import MemberDetails from "./MemberDetails";

function Profile({ profile, detail, external }) {

    const [profileName, setProfileName] = useState("")
    const [extProfile, setExtProfile] = useState("first_init")
    const [address, setAddress] = useState("first_init")
    const [user, setUser] = useState({ loggedIn: null })
    const { nameAdd } = useParams()
    const navigate = useNavigate()
    useEffect(() => fcl.currentUser().subscribe(setUser), [])

    async function getAddress(name) {
        const response = await fcl.send([
            fcl.script(scripts.lookup),
            fcl.args([
                fcl.arg(name, t.String)
            ]),
        ])
        const addressResult = await fcl.decode(response);
        setAddress(addressResult)
    }

    async function getProfile(address) {
        const response = await fcl.send([
            fcl.script(scripts.status),
            fcl.args([
                fcl.arg(address, t.Address)
            ]),
        ])
        const profileData = await fcl.decode(response);
        setExtProfile(profileData)
    }

    useEffect(async () => {
        if (external === "true") {
            let addressSearch = false
            let searchName = nameAdd.toLowerCase()
            searchName = searchName.replace(/[^a-z0-9-]/g, '')
            if (searchName.length < 3 || searchName.length > 16) {
                if (searchName.length === 18 && searchName.includes("0x")) {
                    addressSearch = true
                }
                else
                    navigate("/")
            }
            if (!addressSearch) {
                getAddress(searchName)
            } else {
                setAddress(searchName)
            }
        }
    }
        , [nameAdd])
    useEffect(() => {
        if (address && address !== "first_init") {
            getProfile(address)
        }
        if (!address) {
            navigate("/")
        }
    }, [address])

    if (!user.loggedIn && external !== "true") {
        return (
            <Container className="my-5 py-5" align="center">
                <Row>
                    <Col><h1>Please log in</h1></Col>
                </Row>
                <Row>
                    <Col><AuthCluster user={user} /></Col>
                </Row>
            </Container>
        )
    }
    if (external === "true" && extProfile === "first_init") {
        return (
            <Container id="loadingBlockchain">
                <Row className="mt-5 p-2">
                    <Col className="d-flex justify-content-center text-center" xs="12">
                        <h1>Loading data from the blockchain...</h1>
                    </Col>
                    <Col className="mt-3 d-flex justify-content-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </Container>)
    }
    if (external !== "true" && !profile) {
        return (
            <Container id="loadingBlockchain">
                <Row className="mt-5 p-2">
                    <Col className="d-flex justify-content-center text-center" xs="12">
                        <h1>Loading data from the blockchain...</h1>
                    </Col>
                    <Col className="mt-3 d-flex justify-content-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </Container>)
    }
    if (user.loggedIn && external !== "true" && profile && profile.profile === null) {
        return (
            <Container className="p-lg-0 my-5">
                <Row className="mb-3 pt-5">
                    <Col className="text-center"><h1>YOU NEED AN ACCOUNT!</h1></Col>
                </Row>
                <Row className="mb-3 justify-content-center">
                    <Col xs="auto"><p>Thanks for logging in for the first time! We need to create you an account. Enter any name you want to create a profile.</p></Col>
                </Row>
                <Row className="mb-3 justify-content-center">
                    <Col xs="auto"><Form.Control name="enterName" placeholder="enter a name" type="text" style={{height: "52px", border: "1px solid #DEDEDE", boxSizing: "border-box", borderRadius: "34px" }} onChange={(e) => setProfileName(e.target.value)} /></Col>
                </Row>
                <Row className="justify-content-center pb-5">
                    <Col xs="auto"><Button variant="dark" onClick={() => profileName !== "" && handleCreateNeoUser(profileName)}>Create Profile</Button></Col>
                </Row>
                {/* <div align="center"><Button variant="dark" onClick={() => handleCreateAdminCollection1st()}>Create Admin</Button></div> */}
            </Container>)
    }
    if (user.loggedIn && external !== "true" && profile && profile.voucherCollection === false) {
        return (
            <Container className="p-lg-0 my-5">
                <Row className="mb-3 pt-5">
                    <Col className="text-center"><h1>YOU NEED A COLLECTION!</h1></Col>
                </Row>
                <Row className="mb-3 justify-content-center">
                    <Col xs="auto"><p>Hi {profile.profile.name} we see you have a .find profile already which is great! We just need to create the Neo Collection.</p></Col>
                </Row>
                <Row className="justify-content-center pb-5">
                    <Col xs="auto"><Button variant="dark" onClick={() => handleCreateNeoCollections()}>Create Collection</Button></Col>
                </Row>
                {/* <div align="center"><Button variant="dark" onClick={() => handleCreateAdminCollection1st()}>Create Admin</Button></div> */}
            </Container>)
    }
    if(external === "true" && detail === "true" && extProfile && extProfile.profile !== null){
        return (<MemberDetails profile={extProfile} />)
    }
    if (external === "true" && extProfile && extProfile.profile !== null) {
        return (
            <Container className="p-0" fluid>
                <ProfileHead profile={extProfile.profile} external="true" />
                <ProfileTabsSection extProfile={extProfile} external="true" />
                {/* <div align="center"><Button variant="dark" onClick={() => handleCreateAdminCollection1st()}>Create Admin</Button></div> */}
            </Container>
        )
    }
    if (external === "true" && extProfile && extProfile.profile === null) {
        navigate("/")
    }
    return (
        <Container className="p-0" fluid>
            <ProfileHead profile={profile.profile} />
            <ProfileTabsSection profile={profile} />
            {/* <div align="center"><Button variant="dark" onClick={() => handleCreateAdminCollection1st()}>Create Admin</Button></div> */}
        </Container>
    )
}
export default Profile