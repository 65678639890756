import * as fcl from "@onflow/fcl";
import toast from "react-hot-toast";
import { LoadingNft } from "../components/LoadingNft/LoadingNft";
import { changeState, disableForm, enableForm } from "./DisabledState";

//This is the FCL transaction handler.

const noop = async () => { };

export const Tx = async (mods = [], opts = {}) => {
  const onStart = opts.onStart || noop;
  const onSubmission = opts.onSubmission || noop;
  const onUpdate = opts.onUpdate || noop;
  const onSuccess = opts.onSuccess || noop;
  const onError = opts.onError || noop;
  const onComplete = opts.onComplete || noop;
  //const toastId = toast.loading('Preparing transaction')

  let hasSubmitted = 0

  try {
    onStart();
    var txId = await fcl.send(mods).then(fcl.decode).then(disableForm());
    console.info(
      `%cTX[${txId}]: ${fvsTx(await fcl.config().get("env"), txId)}`,
      "color:purple;font-weight:bold;font-family:monospace;"
    );

    onSubmission(txId);
    var unsub = await fcl.tx(txId).subscribe(onUpdate);
    var txStatus = await fcl.tx(txId).onceSealed();
    unsub();
    console.info(
      `%cTX[${txId}]: ${fvsTx(await fcl.config().get("env"), txId)}`,
      "color:green;font-weight:bold;font-family:monospace;"
    );
    await onSuccess(txStatus).then(changeState());
    return txStatus
  } catch (error) {
    console.error(
      `TX[${txId}]: ${fvsTx(await fcl.config().get("env"), txId)}`,
      error
    );
    onError(error);
    if (hasSubmitted === 0) {
      enableForm()
    }
    if (hasSubmitted === 1) {
      enableForm()
    }
  } finally {
    await onComplete(txStatus);
  }
};

const fvsTx = (env, txId) => `https://flow-view-source.com/${env}/tx/${txId}`;
