import HeaderReveal from "../components/RevealStandard/HeaderReveal"

import { useEffect, useRef, useState } from "react";
import { Col, Row, ProgressBar, Button, Image, Container, Spinner } from "react-bootstrap";
import GraffleSDK from "../functions/Graffle";
import * as fcl from "@onflow/fcl";
import { Tx } from "../functions/transaction";
import * as t from "@onflow/types";
import { transactions, scripts } from 'neo-flow-contracts'
import {  useNavigate, useParams } from "react-router-dom";
import { changeState } from "../functions/DisabledState";

function RevealStandard({ voucher, profile }) {
  //console.log(profile)
 //const [loadingProgress, setLoadingProgress] = useState(0)
  const [errorState, setErrorState] = useState(null)
  const [loadingText, setLoadingText] = useState("")
  const [loadingSubtext, setLoadingSubtext] = useState("we are just claiming your NFT - this may take a few moments")
  const [loadProgress, setLoadProgress] = useState(0)
  const [latestMessage, setLatestMessage] = useState()
  const [nftData, setNftData] = useState()

  const { id } = useParams();

  const [user, setUser] = useState({ loggedIn: null })
  useEffect(() => fcl.currentUser().subscribe(setUser), [])

  //const navigate = useNavigate()

  //    console.log(this.props.location.state)
  const handleRedeemVoucher = async (id) => {
    try {
      await Tx(
        [
          fcl.transaction(transactions.redeemNeoVoucher),
          fcl.args([
            fcl.arg(parseInt(id), t.UInt64)
            // fcl.arg("0x60fcd8cb556cf57f", t.Address)
          ]),
          fcl.proposer(fcl.currentUser().authorization),
          fcl.payer(fcl.currentUser().authorization),
          fcl.authorizations([fcl.currentUser().authorization]),
          fcl.limit(9999),
        ],
        {
          onStart() {
            console.log("start")
            setLoadProgress(25)
            setLoadingText("Starting the engine...")
            setLoadingSubtext("Your transaction is being sent to the blockchain.")
          },
          onSubmission() {
            console.log("submitted")
            setLoadProgress(50)
            setLoadingText("Are you ready?")
            setLoadingSubtext("You're at the first corner... how will you do?")
          },
          async onSuccess(status) {
            setLoadingText("Selecting your Team and Role...")
            setLoadingSubtext("You're racing towards the finish line")
            setLoadProgress(75)
          },
          async onError(error) {
            if (error) {
              const { message } = error;
              setLoadingText("Oops. An error has occurred")
              setLoadingSubtext(error)
            }
          },
        }
      );
    } catch (e) {
      console.log(e);
    }
  }

  async function getNftData(address, id) {
    const response = await fcl.send([
        fcl.script(scripts.member),
        fcl.args([
            fcl.arg(address, t.Address),
            fcl.arg(parseInt(id), t.UInt64)
        ]),
    ])
    const nftData = await fcl.decode(response);
    setNftData({
      "role": nftData.role,
      "teamId": nftData.teamId,
      "memberId": nftData.id,
      "edition": nftData.edition,
      "maxEdition": nftData.maxEdition
  }
      )
}

  useEffect(() => {
    if (!voucher && user.loggedIn) {
      getNftData(user.addr, id)
      setLoadProgress(100)
    }
    if (voucher && user.loggedIn) {
      //console.log(profile.address)
      handleRedeemVoucher(id);
    }
  }, [user]);

  useEffect(() => {
  }, [latestMessage]
  )

  let conn = useRef();
  useEffect(() => {
    if (voucher && profile) {
      const streamSDK = new GraffleSDK();
      const feed = async (message) => {
        //console.log(message)
        //message received, do something (need to filter)
        if (message.flowEventId === "A.b25138dbf45e5801.NeoVoucher.Consumed") {
          //console.log(message.blockEventData.address)
          //console.log(profile.address)
          if(message.blockEventData.voucherId === parseInt(id)){
            setNftData({
              "role": message.blockEventData.role,
              "teamId": message.blockEventData.teamId,
              "memberId": message.blockEventData.memberId,
              "edition": message.blockEventData.edition,
              "maxEdition": message.blockEventData.maxEdition
          })
            changeState()
            setLoadProgress(100)
          }
          
        }

        setLatestMessage(message);
        //console.log(message);

      };
      async function startConn() {
        //console.log("Creating the stream")
        conn.current = await streamSDK.stream(feed);
      }
      startConn()
    }
  }, [voucher, profile]);
  useEffect(() => () => {
    if(voucher)
    //console.log("Stopping the connection")
    conn.current.stop()
  }, []);
  if (errorState === null) {
    if (loadProgress !== 100 && voucher) {
      return (
        <div className="LoadingBG p-3">
          <div className="LoadingNft p-3" align='center'>
            <Row className="mt-4 mb-3">
              <Col>
                <h1>{loadingText}</h1>
                <p>{loadingSubtext}</p>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col className="px-5">
                <Image src='/pages/images/Loading/neo-bike-small.webp' fluid />
                <ProgressBar variant="success" animated now={loadProgress} />

              </Col>
            </Row>
            <Row className="mx-3 mb-5">
              <Col className="my-auto" xs='12' lg='6'>Wanna do something whilst you wait?</Col>
              <Col xs='12' lg='6' className="mt-3 mt-lg-0">
                <a href='https://discord.gg/neonft' target="_blank"><Button variant='dark'>Join our discord</Button></a>
              </Col>
            </Row>
          </div>
        </div>
      )
    }
    if(!voucher && !nftData) {
      <div>waiting for nftData</div>
    }
    else if(!voucher && nftData && nftData !== ""){
      return(
        <div>
          <HeaderReveal nftData={nftData} />
        </div>
      )
    }
    else if(voucher && nftData) {
      return (
        <div>
          <HeaderReveal nftData={nftData} />
        </div>
      )
    }
    else if(!profile || profile === ""){
      return(<Container id="loadingBlockchain">
      <Row className="mt-5 p-2">
        <Col className="d-flex justify-content-center text-center" xs="12">
          <h1>You do not have a profile</h1>
        </Col>
        <Col className="mt-3 d-flex justify-content-center">
         <Button>Create One</Button>
        </Col>
      </Row>
    </Container>)
    }
      return(<Container id="loadingBlockchain">
      <Row className="mt-5 p-2">
        <Col className="d-flex justify-content-center text-center" xs="12">
          <h1>Loading data from the blockchain...</h1>
        </Col>
        <Col className="mt-3 d-flex justify-content-center">
          <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
          </Spinner>
        </Col>
      </Row>
    </Container>)
  }

}

export default RevealStandard 