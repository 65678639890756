import { React } from "react";
import RoleCarousel from "../components/Home/RoleCarousel";
import FindOutMore from "../components/Home/FindOutMore";
import { Container } from "react-bootstrap";
import { BuildRacingTeams } from "../components/Home/BuildRacingTeams";
import { HeaderHome } from "../components/Home/HeaderHome";
import '../components/Home/home.css'
import { TeamsRoles } from "../components/Home/TeamsRoles";
import Spacer from "../components/Spacer";
import { SignYourContract } from "../components/Home/SignYourContract";
import { MovingBanner } from "../components/Home/MovingBanner";
import RoleInformation from "../components/Home/RoleInformation";
import { KeyToUtility } from "../components/Home/KeyToUtility";

function Home() {

  return (
    <Container fluid className="g-0 p-0">
      <MovingBanner nft={true}/>
      <HeaderHome />
      <SignYourContract/>
      <MovingBanner nft={true}/>
      <KeyToUtility/>
      <Spacer/>
      <RoleInformation />
      <MovingBanner nft={true}/>
      <BuildRacingTeams />
      
      
      {/* <RoleCarousel /> */}
      {/* <TeamsRoles /> */}
      {/* <Spacer /> */}
      <FindOutMore />
      
    </Container>
  );
}

export default Home