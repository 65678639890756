import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Breadcrumbs } from "../../functions/Breadcrumbs";
import { useEffect, useState } from "react"
import * as fcl from "@onflow/fcl";

import Spacer from "../Spacer";
import { useParams } from "react-router-dom";

export function ProfileHead({ profile }) {
    let bcprops 
    
    const { nameAdd } = useParams();

    if(!nameAdd) {
    bcprops = {
        title: "My Profile",
        link: "/profile",
    }
}else{
    bcprops = {
        title: " Member > "+nameAdd,
        link: "/profile",
}
}
    if(!profile){
        return(
            <Container id="loadingBlockchain">
            <Row className="mt-5 p-2">
              <Col className="d-flex justify-content-center text-center" xs="12">
                <h1>Loading data from the blockchain...</h1>
              </Col>
              <Col className="mt-3 d-flex justify-content-center">
                <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>
            </Row>
          </Container>
        )
    }
    return (
        <Container id="UserProfile" fluid className="p-0">
            <Breadcrumbs props={bcprops} />
            <Container>
                <Row>
                    <Col className="Profile mx-auto mb-5" xs="auto">
                        <img className="ProfileImg" src={profile.avatar} />
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col align="center">
                        <span className="ProfileName">{profile.name}</span>
                    </Col>
                </Row>
                <Row>
                    <Col align="center">
                        <p className="ProfileAddress">{profile.address} {profile.findName && <>| <a href={"https://find.xyz/"+profile.findName} target="_blank">{profile.findName}.find</a></>}</p>
                        <p className="ProfileMemberSince" style={{whiteSpace: "pre-wrap"}}>{profile?.description}</p>
                    </Col>
                </Row>
                <Row className="mb-3 d-flex justify-content-center">
                    <Col xs="auto">
                        <img src="/pages/images/profile/facebook.png" />
                    </Col>
                    <Col xs="auto">
                        <img src="/pages/images/profile/twitter.png" />
                    </Col>
                    <Col xs="auto">
                        <img src="/pages/images/profile/instagram.png" />
                    </Col>
                    <Col xs="auto">
                        <img src="/pages/images/profile/youtube.png" />
                    </Col>
                    <Col xs="auto">
                        <img src="/pages/images/profile/discord.png" />
                    </Col>
                </Row>
                <Spacer />
                {/* {JSON.stringify(profile, null, 2)} */}
            </Container>
        </Container>
    )
}