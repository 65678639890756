import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { CountdownTimer } from "./CountdownTimer";
import "./home.css"
import { useRecoilValue } from "recoil";
import { disabledState } from "../../atomfile";

// header home
export function HeaderHome() {

  const dis_state = useRecoilValue(disabledState);

    return (
        <Container fluid id="header" className="HeaderHome h-100">
        <div >
          <Row>
            <Col className=" p-3 mt-5">
              <div className="w-100 h-100">
              <div className="HeaderCardBg mx-auto mb-5 pb-5">
                <Row>
                  {/* DESKTOP */}
                  <Col className="mt-5 d-none d-md-block">
                    <h1 className="text-center">WELCOME TO THE WORLD OF</h1>
                  </Col>
                  {/* MOBILE */}
                  <Col className="mt-5 d-block d-sm-none">
                    <h1 className="text-center h1-mobile">WELCOME TO THE WORLD OF</h1>
                  </Col>
                </Row>
                <Row>
                  {/* DESKTOP */}
                  <Col className="d-none d-md-block">
                    <h1 align="center" className="d-md-block d-none mask-text header-title-md">NEO COLLECTIBLES</h1>
                  </Col>
                  {/* MOBILE */}
                  <Col className=" d-md-none d-flex justify-content-center pt-1">
                    <h1 align="center" className="d-md-none mask-text header-title-xs">NEO<br></br>COLLECTIBLES</h1>
                  </Col>
                </Row>
                <Row>
                  <Col className="m-4 mx-5 px-5 text-center d-md-block d-none">
                    <p>Let the community include you, the utility provide you, and the speed excite you. <br></br> <b>Released 28th February.</b></p>
                  </Col>
                  <Col className="m-4 px-4 text-center d-sm-block d-md-none">
                    <p className="small">Let the community include you, the utility provide you, and the speed excite you. <br></br> <b>Released 28th February.</b></p>
                  </Col>
                </Row>
                <Row>
                  {/* DESKTOP */}
                  <Col style={{zIndex: "1"}} className="d-md-block d-none justify-content-center">
                    <div align="center" style={{zIndex: "1"}}><Button disabled href="#VoucherPurchase" variant="dark" style={{zIndex: "1", textDecoration:"none", lineHeight:"30px"}}>Get your voucher</Button></div>
                  </Col>
                  {/* MOBILE */}
                  <Col className="d-md-none d-flex justify-content-center">
                    <div className="w-100 pl-4 pr-4" align="center" style={{zIndex: "1", lineHeight:"20px"}}><Button  disabled href="#VoucherPurchase" className="w-100" variant="dark" style={{zIndex: "1", textDecoration:"none", lineHeight:"30px"}}>Get your voucher</Button></div>
                  </Col>
                </Row>
                {/* DESKTOP */}
                <Row className="d-md-block d-none">
                  <CountdownTimer/> 
                </Row>
                <Row className="HeaderBikers d-md-block d-none w-100 h-auto" style={{zIndex: "0"}}>
                  <Col align="center">
                  <Image src="/pages/images/home/header-bikers.webp" fluid style={{maxWidth: "900px"}}/>
                  </Col>
                 
              </Row>
              <div className="HeaderBikersSm d-xs-block d-md-none mx-auto">
                 <Image src="/pages/images/home/header-bikers-sm.webp" fluid />
              </div>

              {/* MOBILE */}
            
                </div>
                
              </div>
              
            </Col>
            <Row className="d-md-none d-flex w-100">
                  <CountdownTimer/> 
                </Row>
          </Row>
        </div>
      </Container>
    )
}