import faunadb from 'faunadb'

const client = new faunadb.Client({ secret: process.env.REACT_APP_FAUNA_KEY,
    domain: 'db.eu.fauna.com',
    // NOTE: Use the correct domain for your database's Region Group.
    port: 443,
    scheme: 'https',})

const q = faunadb.query

export { client , q }