import { client, q } from '../../config/db'

export const getTeamData = (teamIdRef) => client.query(
    q.Get(
        q.Ref(q.Collection('mainnet_collection'), teamIdRef)
      )
)
  .then((ret) => ret)
  .catch(err => console.warn(err))
  
  
export default getTeamData