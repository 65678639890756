import { Container, Row, Col, Button, Image } from "react-bootstrap";
import './FindOutMore.css'
import './home.css'

function FindOutMore() {
    return (
        <Container className="mx-auto">
            <div className='d-block my-5 p p-0 FindOutMore'>
                <Row className="mx-auto">
                    <Col md="auto" lg='6' className="m-5">
                        <Row>
                        <div className="TitleText">Want to be a real racer?</div>
                        </Row>
                        <Row>
                        <h1 className="my-3">INTRODUCING NEO ONE</h1>
                        </Row>
                        <Row>
                        <p>Want to learn more about the Neo Motorcycles debut bike NEO ONE? </p>
                        <p><b>Check out the Neo Motorcycles site for more information.</b></p>
                        </Row>
                        <Row className="pt-4">
                        <Button href="https://neomotorcycles.co.uk" className="d-table-cell m-0 yellow-button" style={{lineHeight:"32px", width:"150px"}}>Find out more</Button>
                        </Row>
                        
                    </Col>
                    <Col md={4} className='d-flex justify-content-center'>
                        <Row>
                            <Image className='pt-3 pb-3' src='/pages/images/neo-bike-side.webp' fluid/>
                        </Row>
                        
                    </Col>
                </Row>
            </div>
        </Container>
    )
}
export default FindOutMore