import { Container, Row, Col, Image, Button } from "react-bootstrap";
import './HeaderReveal.css'
import team from '../../public/pages/images/reveal-standard/teams/team-200.png'
import { useState, useEffect } from "react";
import standardbackground from '../../public/pages/images/reveal-standard/backgrounds/standard-background.webp';
import spectatorbackground from '../../public/pages/images/reveal-standard/backgrounds/spectator-background.webp';
import crewchiefbackground from '../../public/pages/images/reveal-standard/backgrounds/crew-chief-background.webp';
import pitcrewbackground from '../../public/pages/images/reveal-standard/backgrounds/pit-crew-background.webp';
import racedirectorbackground from '../../public/pages/images/reveal-standard/backgrounds/race-director-background.webp';
import standardconfetti from '../../public/pages/images/reveal-standard/videos/standard-confetti.webm';
import rareconfetti from '../../public/pages/images/reveal-standard/videos/rare-confetti.webm';
import standardconfettim from '../../public/pages/images/reveal-standard/videos/standard-confetti.webm';
import rareconfettim from '../../public/pages/images/reveal-standard/videos/rare-confetti.webm';
import faunadb, { query as q } from 'faunadb'
import { getTeamData } from "../RevealStandard/faunaFunctions"
import { transactions, scripts } from 'neo-flow-contracts'
import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import { Tx } from "../../functions/transaction";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";





export function HeaderReveal({ nftData }) {
    const [teamData, setTeamData] = useState([])
    const [ipfsHash, setIpfsHash] = useState("")
    const [loadingState, setLoadingState] = useState("start")
    const [loadingText, setLoadingText] = useState("Loading. Please Wait.")
    const [loadingSubText, setLoadingSubText] = useState("Loading. Please Wait.")

    var disabledStatus = true

    // console.log(nftData)
    const [selectState, setSelectState] = useState({
        male: "not-selected",
        female: "not-selected",
        avatar: " ",
        status: false
    });

    const refs = {
        "1": "324972442427588802",
        "2": "324972450177614018",
        "3": "324972454388695234",
        "4": "324972457592094914",
        "5": "324972461489651906",
        "6": "324972464326049986",
        "7": "324972468539228354",
        "8": "324972473120456898",
        "9": "324972476376285378",
        "10": "324972479319638210"
    }
    const ipfs_url = "https://ipfs.neocollectibles.xyz/ipfs/"

    const handleSetMemberMedia = async (id, mediaHash, mediaType, thumbnailHash) => {
        

        try {
            await Tx(
                [
                    fcl.transaction(transactions.setMemberMedia),
                    fcl.args([
                        fcl.arg(id, t.UInt64),
                        fcl.arg(mediaHash, t.String),
                        fcl.arg(mediaType, t.String),
                        fcl.arg(thumbnailHash, t.String)
                    ]),
                    fcl.proposer(fcl.currentUser().authorization),
                    fcl.payer(fcl.currentUser().authorization),
                    fcl.authorizations([fcl.currentUser().authorization]),
                    fcl.limit(9999),
                ],
                {
                    onStart() {
                        setLoadingState("loading")
                        setLoadingText("Approve the transaction.")
                    },
                    onSubmission() {
                        setLoadingText("Your lanyard is being built.")
                    },
                    async onSuccess(status) {
                        setSelectState({ male: selectState.male, female: selectState.female, avatar: selectState.avatar, status: true })
                        setIpfsHash(mediaHash)
                    },
                    async onError(error) {
                        if (error) {
                            const { message } = error;
                            setLoadingState("start")
                        }
                    },
                }
            );
        } catch (e) {
            console.log(e);
        }
    }



    useEffect(() => {
        getTeamData(refs[nftData.teamId]).then(res => setTeamData(res))
    }, [])



    var role = nftData.role.replace("Team Member", "spectator").toLowerCase()
    var background_image = standardbackground
    var confetti = ""
    var confetti_m = ""
    var avatarImageM = ""
    var avatarImageF = ""
    var role_c = ""

    switch (role) {

        case 'spectator':
            avatarImageM = "/pages/images/reveal-standard/spectator-m-podium.png"
            avatarImageF = "/pages/images/reveal-standard/spectator-f-podium.png"
            role_c = "Team Member"
            background_image = spectatorbackground;
            confetti = standardconfetti
            confetti_m = standardconfettim
            break

        case 'pit crew':
            avatarImageM = "/pages/images/reveal-standard/pit-crew-n-podium.png"
            background_image = pitcrewbackground;
            confetti = standardconfetti
            confetti_m = standardconfettim
            role_c = "Pit Crew"
            break

        case 'crew chief':
            avatarImageM = "/pages/images/reveal-standard/crew-chief-m-podium.png"
            avatarImageF = "/pages/images/reveal-standard/crew-chief-f-podium.png"
            background_image = crewchiefbackground;
            confetti = rareconfetti
            confetti_m = rareconfettim
            role_c = "Crew Chief"
            break

        case 'race director':
            avatarImageM = "/pages/images/reveal-standard/race-director-m-podium.png"
            avatarImageF = "/pages/images/reveal-standard/race-director-f-podium.png"
            background_image = racedirectorbackground;
            confetti = rareconfetti
            confetti_m = rareconfettim
            role_c = "Race Director"
            break
    }

    if (!selectState.status) {
        if(selectState.female === "not-selected" && selectState.male ==="not-selected") {
            
            disabledStatus = true
        }
        else {
            disabledStatus = false
        }

        if(role_c == "Pit Crew")
            {
                disabledStatus = false
            }
        var gender = selectState.avatar
        if (selectState.avatar === "female") {
            gender = "ipfs_f"
        }
        else if (selectState.avatar === "male") {
            gender = "ipfs_m"
        }
        else {
            gender = "ipfs_n"
        }

        if(loadingState !== "loading") {

        return (
            <Container fluid className="min-vh-100 my-auto LoadingRoleBG" style={{ backgroundImage: "url(" + background_image + ")" }}>
                <video className="d-md-none" id='confetti-video' style={{ width: "100%", position: 'absolute', marginLeft: "-15px" }} src={confetti_m} autoplay="autoplay" muted></video>
                <video className="d-none d-md-block" id='confetti-video' style={{ width: '100%', position: 'absolute' }} src={confetti} autoplay="autoplay" muted></video>
                <script>
                    document.getElementById('confetti-video').play();
                </script>
                <Container className='d-none d-md-block h-75'>
                    <Row className='h-100 align-items-center'>
                        <Col>
                            <div className="HeaderCardBg mx-auto mt-5 mb-5 pb-3">
                                <Row>
                                    <Col className="mt-4">
                                        <h1 id='reveal-header-h1' className="text-center m-0 p-0">CONGRATULATIONS</h1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-center">
                                        <p id='reveal-header-p' className="p-0 m-0">You just got a <span className={nftData.role.replace(/ /g, '') + "Styling"}><b>{nftData.role}</b></span> in <b>Team {nftData.teamId}</b></p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mx-5 px-5 text-center d-md-block d-none">
                                        <p className="pb-4 pt-1 m-0" id="reveal-header-p-sub">Choose your {nftData.role}'s avatar!</p>
                                    </Col>
                                </Row>
                                {nftData.role !== "Pit Crew" &&
                                    <Row className="">
                                        <Col md='6' className='d-flex justify-content-end'><div className="d-flex justify-content-end">
                                            <div id='avatar-clickable' className={selectState.male}><Image src={avatarImageM} onClick={() => setSelectState({ male: "selected", female: "not-selected", avatar: "male" })} fluid /></div>

                                        </div></Col>
                                        {/* <Col md='6' className="pb-5">
                                        <Image src="/pages/images/nft-placeholder.png" fluid />
                                    </Col> */}
                                        <Col md='6'><div className="d-flex justify-content-start">
                                            <div id='avatar-clickable' className={selectState.female}><Image src={avatarImageF} onClick={() => setSelectState({ male: "not-selected", female: "selected", avatar: "female" })} fluid /></div>
                                        </div></Col>
                                    </Row>
                                }
                                {nftData.role === "Pit Crew" &&
                                    <Row className="">
                                        <Col md='' className='d-flex justify-content-center'><div className="d-flex justify-content-end">
                                            <div id='avatar-clickable' ><Image src={avatarImageM} onClick={() => setSelectState({ male: "selected", female: "selected", avatar: "neutral" })} fluid /></div>

                                        </div></Col>
                                    </Row>
                                }

                                <Row className='m-3'>
                                    <Col className='d-flex justify-content-center'>
                                        <Button variant='dark' disabled={disabledStatus} onClick={() => handleSetMemberMedia(nftData.memberId, teamData.data[role_c][nftData.edition][gender], "PNG", teamData.data[role_c][nftData.edition][gender + "_thumbnail"],)}>Build NFT</Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <div className='d-md-none h-75 p-2'>
                    <Row className='h-100 align-items-center'>
                        <Col>
                            <div className="HeaderCardBg mx-auto mt-5 mb-5 pb-3">
                                <Row>
                                    <Col className="mt-4">
                                        <h1 id='reveal-header-h1' className="text-center m-0 p-0">CONGRATULATIONS</h1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-center">
                                        <p id='reveal-header-p' className="p-0 m-0">You just got a <span className={nftData.role.replace(/ /g, '') + "Styling"}><b>{nftData.role}</b></span> in <b>Team {nftData.teamId}</b></p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mx-5 px-5 text-center d-md-block d-none">
                                        <p className="pb-4 pt-1 m-0" id="reveal-header-p-sub">Choose your {nftData.role}'s avatar!</p>
                                    </Col>
                                </Row>
                                {nftData.role !== "Pit Crew" &&
                                    <Row className='p-2'>
                                        <Col className='d-flex justify-content-end'><div className="d-flex justify-content-end">
                                            <div id='avatar-clickable' className={selectState.male}><Image src={avatarImageM} onClick={() => setSelectState({ male: "selected", female: "not-selected", avatar: "male" })} fluid /></div>

                                        </div></Col>
                                        <Col><div className="d-flex justify-content-start">
                                            <div id='avatar-clickable' className={selectState.female}><Image src={avatarImageF} onClick={() => setSelectState({ male: "not-selected", female: "selected", avatar: "female" })} fluid /></div>
                                        </div></Col>
                                    </Row>
                                }
                                {nftData.role === "Pit Crew" &&
                                    <Row className="">
                                        <Col xs={12} className='d-flex justify-content-center'><div className="d-flex justify-content-end">
                                            <div id='avatar-clickable' ><Image src={avatarImageM} onClick={() => setSelectState({ male: "selected", female: "selected", avatar: "neutral" })} fluid /></div>

                                        </div></Col>
                                    </Row>
                                }

                                <Row className='m-3'>
                                    <Col className='d-flex justify-content-center'>
                                        <Button variant='dark' disabled={disabledStatus} onClick={() => handleSetMemberMedia(nftData.memberId, teamData.data[role_c][nftData.edition][gender], "PNG", teamData.data[role_c][nftData.edition][gender + "_thumbnail"],)}>Build NFT</Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>


                {/* <Container className='d-md-none'>
                    <Container>
                        <div className="HeaderCardBg h">
                            <Row className='d-block h-100 align-items-center' >
                                <Col className="">
                                    <Row>
                                        <Col className="m-0 p-0">
                                            <h1 id='reveal-header-h1' className="text-center m-0 p-0" style={{}}>CONGRATULATIONS</h1>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="d-flex justify-content-center">
                                            <p id='reveal-header-p' className="p-0 m-0">You just got a <span className={nftData.role.replace(/ /g, '') + "Styling"}><b>{nftData.role}</b></span> in Team <span><Image src={team} /></span></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-center">
                                            <p className=" pt-1 m-0" id="reveal-header-p-sub">Choose your {nftData.role}'s avatar!</p>
                                        </Col>
                                    </Row>

                                    {/* <Row>
                                        <Col className="pb-4 pt-4">
                                            <Image src="/pages/images/nft-placeholder.png" fluid />
                                        </Col>
    
                                    </Row> */}

                {/* <Row className='p-2'>
                                        <Col className='d-flex justify-content-end'><div className="d-flex justify-content-end">
                                            <div id='avatar-clickable' className={selectState.male}><Image src={avatarImageM} onClick={() => setSelectState({ male: "selected", female: "not-selected", avatar: "male" })} fluid /></div>

                                        </div></Col>
                                        <Col><div className="d-flex justify-content-start">
                                            <div id='avatar-clickable' className={selectState.female}><Image src={avatarImageF} onClick={() => setSelectState({ male: "not-selected", female: "selected", avatar: "female" })} fluid /></div>
                                        </div></Col>
                                    </Row>
                                    <Row className='m-3'>
                                        <Col className='d-flex justify-content-center'>
                                            <Button style={{ width: "100%" }} variant='dark' onClick={() => handleSetMemberMedia(nftData.memberId, teamData.data[role_c][nftData.edition][gender], "PNG", teamData.data[role_c][nftData.edition][gender + "_thumbnail"],)}>Build NFT</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Container> */}
            </Container>
        )
            } else {
                return (
                        <Container fluid className="min-vh-100 my-auto LoadingRoleBG" style={{ backgroundImage: "url(" + background_image + ")" }}>
                            <Container className='h-75'>
                                <Row className='h-100 align-items-center'>
                                    <Col>
                                        <div className="HeaderCardBg mx-auto mt-5 mb-5 pb-5">
                                            <h1 className="text-center pt-5">{loadingText}</h1>
                                            <p className="text-center">{loadingSubText}</p>
                                            <div className="pb-3" align="center"><ClipLoader className="align-items-center" size={150} /></div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
            
                            {/* <div className='d-md-none h-75 p-2'>
                                <Row className='h-100 align-items-center'>
                                    <Col>
                                        <div className="HeaderCardBg mx-auto mt-5 mb-5 pb-3">

                                            <h1 className="text-align-center">{loadingText}</h1>
                                                <p>{loadingSubText}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div> */}
            
                        </Container>
                )
            }
    }
    else {

        var gender = selectState.avatar

        switch (gender) {

            case 'female':
                gender = "ipfs_f"
                break
    
            case 'male':
                gender = "ipfs_m"
                break
    
            case 'neutral':
                gender = "ipfs_n"
                break
        }

        

        var count = 0

        if (teamData && nftData) {
            // console.log(teamData[nftData.role])
            return (

                <div fluid className="min-vh-100 my-auto " style={{ backgroundImage: "url(" + background_image + ")", backgroundSize: "cover" }}>
                    <Container className="vh-75">
                        <Row className="pt-5 pb-1 h-100 align-items-center">
                            <Col>
                                <div className="HeaderCardBg mx-auto p-2 mt-5 my-auto">
                                    <div className="mb-4">
                                        <h1 style={{ textAlign: 'center' }}>TEAM {nftData.teamId} </h1>
                                        <p style={{ textAlign: 'center' }}>{nftData.role} - {nftData.edition} of {nftData.maxEdition}</p>
                                        <Row align="center" className="mx-auto align-middle">
                                            <Col>
                                                <Image className="pb-4 pl-4 pr-4 pt-2 nft-view" fluid src={ipfs_url + ipfsHash}></Image>
                                            </Col>

                                        </Row>
                                        <Row className="mx-auto w-100">
                                        <Col className="d-none d-md-block">
                                            </Col>
                                        <Col className="mt-3" xs={6} md={5}>
                                                <a href={ipfs_url + ipfsHash} download><Button className="w-100 text-align-center" variant="dark">Download</Button></a>
                                            </Col>
                                            
                                            <Col className="mt-3" xs={6} md={5}>
                                                <Link to="/profile"><Button className="w-100" variant="dark">Profile</Button></Link>
                                            </Col>
                                            <Col className="d-none d-md-block">
                                            </Col>
                                            
                                        </Row>
                                    </div>
                                </div>
                            </Col>

                        </Row>


                    </Container>
                </div>
            )
        }


        // <div fluid id="revealHeader" style={{ backgroundImage: "url(" + background_image + ")" }} className='RevealHeaderHome '>


        // {/* <Container fluid id="revealHeader" style={{ backgroundImage: "url(" + background_image + ")" }} className='RevealHeaderHome '> */}
        //     <Container fluid id="revealHeader" className='RevealHeaderHome'>
        //         <Container className='d-none d-md-block'>
        //             <Row className=''>
        //                 <Col className="m-5 p-4 mx-auto">
        //                     <div className="HeaderCardBg mx-auto p-1 w-75">
        //                         <h1 style={{ textAlign: 'center' }}>TEAM {nftData.teamId} </h1>
        //                         <h2 style={{ textAlign: 'center' }}>{nftData.role} - {nftData.edition} of {nftData.maxEdition}</h2>
        //                         <Row align="center" className="mx-auto">
        //                             <Col>
        //                             <Image className="pb-4 pl-4 pr-4 pt-2 nft-view" fluid src={ipfs_url + teamData.data[role_c][nftData.edition][gender]}></Image>
        //                             </Col>

        //                         </Row>

        //                     </div>
        //                 </Col>
        //             </Row>
        //         </Container>

        //     <Container className='d-md-none p-0 m-0'>
        //     <Row className=''>
        //                 <Col className="m-5 mx-auto">
        //                     <div className="HeaderCardBg mx-auto p-1 w-100">
        //                         <h1 style={{ textAlign: 'center' }}>TEAM {nftData.teamId} </h1>
        //                         <h2 style={{ textAlign: 'center' }}>{nftData.role} - {nftData.edition} of {nftData.maxEdition}</h2>
        //                         <Row align="center" className="mx-auto">
        //                             <Col>
        //                             <Image className="pb-4 pl-4 pr-4 pt-2 nft-view-m" fluid src={ipfs_url + teamData.data[role_c][nftData.edition][gender]}></Image>
        //                             </Col>

        //                         </Row>

        //                     </div>
        //                 </Col>
        //             </Row>
        //     </Container>
        // </Container>

        // </div>
    }

}


export default HeaderReveal;