import { Breadcrumb, Container } from "react-bootstrap";
import { Link } from "../../node_modules/react-router-dom";

export function Breadcrumbs({props}) {
    if(!props)
    return null

    return (
        <Container fluid className="p-0" style={{borderTop: "solid 1px #c4c4c4"}}>
            <Container className="p-0">
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: props.link }} active>{props.title}</Breadcrumb.Item>
                </Breadcrumb>
            </Container>
            {!props?.hideBanner &&
            <div fluid className="bannerBelowBreadcrumb m-0 p-0"></div>}
        </Container>
    )
}