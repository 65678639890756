import './RoleCarousel.css'
import { Container, Row, Col, Image, Tabs, Tab } from 'react-bootstrap'
import { tabdata } from '../Mint/TabContent/tabdata'
import { RoleCarouselItems } from './RoleCarouselItems'
const roleData = tabdata
function RoleInformation(props) {
    return (
        <Container id="RoleInformation" className=' mt-5 p-lg-0 '>
            <Row>
                <Col>
                    <div className='mt-0 mb-5 mt-5 pt-5' align="center">
                        <h1 style={{fontSize:"50px"}}>FIND THE PERFECT ROLE FOR YOU</h1>
                        <p>Your lanyard doesnt just look amazing, it gives you amazing things. Each role will have rewards worth at least $600.</p>
                    </div>
                </Col>
            </Row>

            <div className="d-none d-md-block">

            
            {/* DESKTOP */}
            <Tabs defaultActiveKey={"spectator"} id="RoleInformation" className="mb-5 ">
                <Tab eventKey="spectator" title={<span className=''><Image src="/pages/images/home/spectator-select.png"/> <div className="text-center my-1">Team Member</div> </span>}>
                    {/* <Spacer />
                            <ExploreTabContent props={"spectator"}/> */}
                    <Container>

                        <RoleCarouselItems props={roleData["teammember"]} ></RoleCarouselItems>
                    </Container>
                </Tab>
                <Tab eventKey="pitCrew" title={<span className=''><Image src="/pages/images/home/pit-crew-select.png" /> <div className="text-center my-1">Pit Crew</div> </span>}>
                    {/* <Spacer />
                            <ExploreTabContent props="spectator"/> */}

                    <Container>

                        <RoleCarouselItems props={roleData["pitcrew"]} ></RoleCarouselItems>
                    </Container>
                </Tab>
                <Tab eventKey="crewChief" title={<span className=''><Image src="/pages/images/home/crew-chief-select.png" /> <div className="text-center my-1">Crew Chief</div> </span>}>
                    {/* <Spacer />
                            <ExploreTabContent props="spectator"/> */}

                    <Container>

                        <RoleCarouselItems props={roleData["crewchief"]} ></RoleCarouselItems>
                    </Container>
                </Tab>
                <Tab eventKey="raceDirector" title={<span className=''><Image src="/pages/images/home/race-director-select.png" /> <div className="text-center my-1">Race Director</div> </span>}>
                    {/* <Spacer />
                            <ExploreTabContent props="spectator"/> */}
                    <Container>

                        <RoleCarouselItems props={roleData["racedirector"]} ></RoleCarouselItems>
                    </Container>
                </Tab>
            </Tabs>
            </div>
            {/* MOBILE */}
            <div>

            </div>
            <Row className='d-block d-md-none'>

            <Tabs defaultActiveKey={"spectator"} id="mRoleInformation" className="mb-5 m-0 p-0">
                <Tab eventKey="spectator" className="m-0 p-0" title={<span className=''><Image src="/pages/images/home/spectator-select.png" fluid/> <div className="text-center my-1">Team Member</div> </span>}>
                    {/* <Spacer />
                            <ExploreTabContent props={"spectator"}/> */}
                    <Container>

                        <RoleCarouselItems props={roleData["teammember"]} ></RoleCarouselItems>
                    </Container>
                </Tab>
                <Tab eventKey="pitCrew"  title={<span className=''><Image src="/pages/images/home/pit-crew-select.png" fluid /> <div className="text-center my-1">Pit Crew</div> </span>}>
                    {/* <Spacer />
                            <ExploreTabContent props="spectator"/> */}

                    <Container>

                        <RoleCarouselItems props={roleData["pitcrew"]} ></RoleCarouselItems>
                    </Container>
                </Tab>
                <Tab eventKey="crewChief" title={<span className=''><Image src="/pages/images/home/crew-chief-select.png" fluid/> <div className="text-center my-1">Crew Chief</div> </span>}>
                    {/* <Spacer />
                            <ExploreTabContent props="spectator"/> */}

                    <Container>

                        <RoleCarouselItems props={roleData["crewchief"]} ></RoleCarouselItems>
                    </Container>
                </Tab>
                <Tab eventKey="raceDirector" title={<span className=''><Image src="/pages/images/home/race-director-select.png" fluid/> <div className="text-center my-1">Race Director</div> </span>}>
                    {/* <Spacer />
                            <ExploreTabContent props="spectator"/> */}
                    <Container>

                        <RoleCarouselItems props={roleData["racedirector"]} ></RoleCarouselItems>
                    </Container>
                </Tab>
            </Tabs>
            </Row>
            <Row className="mt-5 mb-5">

            </Row>
        </Container>
        
    )

}

export default RoleInformation