import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import { transactions } from 'neo-flow-contracts'
import { Tx } from "../functions/transaction";
import { Navigate, Redirect } from 'react-router-dom';
import { LoadingNft } from "../components/LoadingNft/LoadingNft";

export const handleCreateNeoUser = async (e) => {
  try {
    await Tx(
      [
        fcl.transaction(transactions.createNeoUser),
        fcl.args([
          fcl.arg(e, t.String)
        ]),
        fcl.proposer(fcl.currentUser().authorization),
        fcl.payer(fcl.currentUser().authorization),
        fcl.authorizations([fcl.currentUser().authorization]),
        fcl.limit(9999),
      ],
      {
        onStart() {
          console.log("start")
        },
        onSubmission() {
          console.log("submitted")
        },
        async onSuccess(status) {
        },
        async onError(error) {
          if (error) {
            const { message } = error;
            console.log(message)
          }
        },
      }
    );
  } catch (e) {
    console.log(e);
  }
}

export const handleCreateNeoCollections = async (e) => {
  try {
    await Tx(
      [
        fcl.transaction(transactions.createNeoCollections),
        fcl.proposer(fcl.currentUser().authorization),
        fcl.payer(fcl.currentUser().authorization),
        fcl.authorizations([fcl.currentUser().authorization]),
        fcl.limit(9999),
      ],
      {
        onStart() {
          console.log("start")
        },
        onSubmission() {
          console.log("submitted")
        },
        async onSuccess(status) {
        },
        async onError(error) {
          if (error) {
            const { message } = error;
            console.log(message)
          }
        },
      }
    );
  } catch (e) {
    console.log(e);
  }
}

export const handleCreateUser = async (e) => {
  try {
    await Tx(
      [
        fcl.transaction(transactions.createUser),
        fcl.proposer(fcl.currentUser().authorization),
        fcl.payer(fcl.currentUser().authorization),
        fcl.authorizations([fcl.currentUser().authorization]),
        fcl.limit(9999),
      ],
      {
        onStart() {
          console.log("start")
        },
        onSubmission() {
          console.log("submitted")
        },
        async onSuccess(status) {
        },
        async onError(error) {
          if (error) {
            const { message } = error;
            console.log(message)
          }
        },
      }
    );
  } catch (e) {
    console.log(e);
  }
}

export const handleBuyvoucher = async (e) => {
  try {
    await Tx(
      [
        fcl.transaction(transactions.buyNeoVoucher),
        fcl.args([
          fcl.arg("10.0", t.UFix64),
          fcl.arg("0x60fcd8cb556cf57f", t.Address)
        ]),
        fcl.proposer(fcl.currentUser().authorization),
        fcl.payer(fcl.currentUser().authorization),
        fcl.authorizations([fcl.currentUser().authorization]),
        fcl.limit(9999),
      ],
      {
        onStart() {
          console.log("start")
        },
        onSubmission() {
          console.log("submitted")
        },
        async onSuccess(status) {
        },
        async onError(error) {
          if (error) {
            const { message } = error;
            console.log(message)
          }
        },
      }
    );
  } catch (e) {
    console.log(e);
  }
}

export const handleCreateUserCollection = async (e) => {
  try {
    await Tx(
      [
        fcl.transaction(`
        import Unique from 0x60fcd8cb556cf57f
        import Editioned from 0x60fcd8cb556cf57f
        import Voucher from 0x60fcd8cb556cf57f
        import FungibleToken from 0x9a0766d93b6608b7
        
        //this needs to be here so that we can use flow-transactions from portto
        transaction(amount: UFix64, marketplace:Address) {
        
        
          let marketplaceVouchers: Capability<&Voucher.Collection{Voucher.CollectionPublic}>
          let vouchersCap: Capability<&Voucher.Collection{Voucher.CollectionPublic}>
          let vaultRef: &FungibleToken.Vault
          let vouchers: &Voucher.Collection
        
          prepare(account: AuthAccount) {
        
            //we run the setupUser transaction incase they do not have antyhing yet
            let uniqueCap=account.getCapability<&Unique.Collection{Unique.CollectionPublic}>(Unique.CollectionPublicPath)
            if !uniqueCap.check() {
              //if we store something from another deploy of the contract in another address when testing this will remove it
              account.unlink(Unique.CollectionPublicPath)
              destroy <- account.load<@AnyResource>(from: Unique.CollectionStoragePath)
        
              account.save(<- Unique.createEmptyCollection(), to: Unique.CollectionStoragePath)
              account.link<&Unique.Collection{Unique.CollectionPublic}>(Unique.CollectionPublicPath, target: Unique.CollectionStoragePath)
            }
        
            let editionCap=account.getCapability<&Editioned.Collection{Editioned.CollectionPublic}>(Editioned.CollectionPublicPath)
            if !editionCap.check() {
              //if we store something from another deploy of the contract in another address when testing this will remove it
              account.unlink(Editioned.CollectionPublicPath)
              destroy <- account.load<@AnyResource>(from: Editioned.CollectionStoragePath)
        
              account.save(<- Editioned.createEmptyCollection(), to: Editioned.CollectionStoragePath)
              account.link<&Editioned.Collection{Editioned.CollectionPublic}>(Editioned.CollectionPublicPath, target: Editioned.CollectionStoragePath)
            }
        
            let vouchersCap=account.getCapability<&Voucher.Collection{Voucher.CollectionPublic}>(Voucher.CollectionPublicPath)
            if !vouchersCap.check() {
              //if we store something from another deploy of the contract in another address when testing this will remove it
              account.unlink(Voucher.CollectionPublicPath)
              destroy <- account.load<@AnyResource>(from: Voucher.CollectionStoragePath)
        
              let vouchers <- Voucher.createEmptyCollection() as! @Voucher.Collection
              account.save(<- vouchers, to: Voucher.CollectionStoragePath)
              account.link<&Voucher.Collection{Voucher.CollectionPublic}>(Voucher.CollectionPublicPath, target: Voucher.CollectionStoragePath)
            }
            self.vouchersCap=vouchersCap
        
            self.vaultRef= account.borrow<&FungibleToken.Vault>(from: /storage/flowTokenVault) ?? panic("Could not borrow owner's Vault reference")
            self.marketplaceVouchers=getAccount(marketplace).getCapability<&Voucher.Collection{Voucher.CollectionPublic}>(Voucher.CollectionPublicPath)
            self.vouchers = account.borrow<&Voucher.Collection>(from: Voucher.CollectionStoragePath) ?? panic("Could not borrow a reference to the owner's voucher")
          }
        
          execute {
            let vault <- self.vaultRef.withdraw(amount: amount)
            //we buy
            let voucherId= self.marketplaceVouchers.borrow()!.buy(vault: <- vault, collectionCapability: self.vouchersCap)
        
            //we redeem immediately
            Voucher.redeem(collection: self.vouchers, voucherID: voucherId)
          }
        }`),
        fcl.args([
          fcl.arg("100.0", t.UFix64),
          fcl.arg("0x60fcd8cb556cf57f", t.Address)
        ]),
        fcl.proposer(fcl.currentUser().authorization),
        fcl.payer(fcl.currentUser().authorization),
        fcl.authorizations([fcl.currentUser().authorization]),
        fcl.limit(9999),
      ],
      {
        onStart() {
          console.log("start")

        },
        onSubmission() {
          console.log("submitted")
        },
        async onSuccess(status) {
          console.log("success")
          const event = document.createEvent("Event");
          event.initEvent("bid", true, true);
          document.dispatchEvent(event);
        },
        async onError(error) {
          if (error) {
            const { message } = error;
            console.log(message)
          }
        },
      }
    );
  } catch (e) {
    console.log(e);
  }
}
// export const handleBuyvoucher = async (e) => {
//   try {
//     await Tx(
//       [
//         fcl.transaction(transactions.buyVoucher),
//         fcl.args([
//           fcl.arg("100.0", t.UFix64),
//           fcl.arg("0x60fcd8cb556cf57f", t.Address)
//         ]),
//         fcl.proposer(fcl.currentUser().authorization),
//         fcl.payer(fcl.currentUser().authorization),
//         fcl.authorizations([fcl.currentUser().authorization]),
//         fcl.limit(9999),
//       ],
//       {
//         onStart() {
//           console.log("start")
          
//         },
//         onSubmission() {
//           console.log("submitted")
//           Navigate
//         },
//         async onSuccess(status) {
//           console.log("success")
//         },
//         async onError(error) {
//           if (error) {
//             const { message } = error;
//             console.log(message)
//           }
//         },
//       }
//     );
//   } catch (e) {
//     console.log(e);
//   }
// }
export const handleCreateAdminCollection1st = async (e) => {
  try {
    await Tx(
      [
        fcl.transaction(transactions.addAchievement),
        fcl.proposer(fcl.currentUser().authorization),
        fcl.payer(fcl.currentUser().authorization),
        fcl.authorizations([fcl.currentUser().authorization]),
        fcl.limit(9999),
      ],
      {
        onStart() {
          console.log("start")

        },
        onSubmission() {
          console.log("submitted")
          
        },
        async onSuccess(status) {
          console.log("success")
          const event = document.createEvent("Event");
          event.initEvent("bid", true, true);
          document.dispatchEvent(event);
        },
        async onError(error) {
          if (error) {
            const { message } = error;
            console.log(message)
          }
        },
      }
    );
  } catch (e) {
    console.log(e);
  }
}
