export const tabdata = {
    "teammember": {
        "image": "/pages/images/avatars/fullsize/Spectator-male.webp",
        "imageF": "/pages/images/avatars/fullsize/Spectator-female.webp",
        "placeholder": "/pages/images/avatars/placeholder/spectator-placeholder.webp",
        "title": "TEAM MEMBER",
        "name": "team member",
        "type": "50 per Team",
        "color": "#119235",
        "price": "F60",
        "rewards": ["Access to Neo-Fest in 2023, 2024 and 2025 | Included with Gold voucher - Also sold separately.",
            "Win huge prizes in the Neo Championship",
            "Join 1 out of 10 exclusive Neo Teams",
            "A unique NFT with your chosen avatar",
            "The chance to win a $50,000 Neo One Motorcycle + NFT",
            "and so much more!"
        ],
        "bio": "Get out of the crowd and into the action with a team member NFT! You’ll have an important role on a Neo Team where you can complete challenges, win prizes and become a part of the Neo Community. Stop watching and start winning!"
    },
    "pitcrew": {
        "image": "/pages/images/avatars/fullsize/Pit-Crew.webp",
        "imageF": "/pages/images/avatars/fullsize/Pit-Crew.webp",
        "placeholder": "/pages/images/avatars/placeholder/pit-crew-placeholder.webp",
        "title": "PIT CREW",
        "name": "pit crew",
        "type": "10 per Team",
        "color": "#1467B4",
        "price": "F60",
        "rewards": ["Access to Neo-Fest in 2023, 2024 and 2025 | Included with Gold voucher - Also sold separately.",
            "Win huge prizes in the Neo Championship",
            "Join 1 out of 10 exclusive Neo Teams",
            "A unique NFT with your chosen avatar",
            "The chance to win a $50,000 Neo One Motorcycle + NFT",
            "and so much more!"
        ],
        "bio": "Make the impossible, possible with a Pit Crew NFT! Being a member of the Pit Crew on a Neo Team is an incredible opportunity to show off your talents and make a big impact on your teams’ success."
    },
    "crewchief": {
        "image": "/pages/images/avatars/fullsize/Crew-chief-male.webp",
        "imageF": "/pages/images/avatars/fullsize/Crew-chief-female.webp",
        "placeholder": "/pages/images/avatars/placeholder/crew-chief-placeholder.webp",
        "title": "CREW CHIEF",
        "name": "crew chief",
        "type": "5 per Team",
        "color": "#DA932A",
        "price": "F60",
        "rewards": ["Premium Access to Neo-Fest in 2023, 2024 and 2025 | Included with Gold voucher - Also sold separately.",
            "Win huge prizes in the Neo Championship",
            "Join 1 out of 10 exclusive Neo Teams",
            "A unique NFT with your chosen avatar",
            "The chance to win a $50,000 Neo One Motorcycle + NFT",
            "and so much more!"
        ],
        "bio": "Keep things moving forward with a Crew Chief NFT! This is your chance to help guide your team on the path to victory and huge prizes as you take on a critical role."
    },
    "racedirector": {
        "image": "/pages/images/avatars/fullsize/Race-director-male.webp",
        "imageF": "/pages/images/avatars/fullsize/Race-director-female.webp",
        "placeholder": "/pages/images/avatars/placeholder/race-director-placeholder.webp",
        "title": "RACE DIRECTOR",
        "name": "race director",
        "type": "1 per Team",
        "color": "#B74AC9",
        "price": "F60",
        "rewards": ["Premium Access to Neo-Fest in 2023, 2024 and 2025 | Included with Gold voucher - Also sold separately.",
            "Win huge prizes in the Neo Championship",
            "Join 1 out of 10 exclusive Neo Teams",
            "A unique NFT with your chosen avatar",
            "The chance to win a $50,000 Neo One Motorcycle + NFT",
            "and so much more!"
        ],
        "bio": "Leave your mark on Neo with the Race Director NFT! You will have some incredible opportunities to be the driving force behind your teams success with one of the rarest roles available."
    },
    "founder": {
        "image": "/pages/images/avatars/fullsize/Founder-male.webp",
        "imageF": "/pages/images/avatars/fullsize/Founder-female.webp",
        "imageMF": "/pages/images/home/RoleCarousel/founder-male-female.webp",
        "title": "FOUNDER",
        "name": "founder",
        "type": "1 per Team",
        "color": "#000",
        "price": "F3000",
        "rewards": ["The Neo One",
            "Lifetime VIP Access to Neo-Fest | ",
            "Head of a Neo Team – Pick your Team, Increased Team Rewards",
            "Earn royalties from sales within your team",
            "The Founder Merch Pack",
            "and so much more that we literally don’t have space for!"
        ],
        "bio": "Being the Head of a Neo Team is not for the faint-hearted but offers you the chance to achieve greatness. With an incredible list of benefits and opportunities – highlighted by the exclusive Neo One."
    }
}
