import { Row, Col, Container, Image, Button } from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { disabledState } from "../../atomfile"

export function KeyToUtility() {
    const dis_state = useRecoilValue(disabledState)
    return (
        <Container>
            <Row className='pb-5 pt-5'>

            </Row>
            <Row>
                <Col>
                    <h1 style={{ fontSize: "50px" }} align="center">YOUR KEY TO UTILITY</h1>
                    <p align="center">Getting a Neo NFT allows you to unlock a world of utility including access to Neo-Fest (More coming soon)</p>
                    {/* <span><Link to="/neo-fest">Neo Fest</Link></span></p>   */}
                </Col>

            </Row>
            <Row>
                <Col>
                    <p align="center" style={{ fontSize: "14px" }}>Examples of your unique Neo NFT</p>
                </Col>

            </Row>
            <Row className="mx-auto">
                <Col className="pb-2" md={3} xs={6}>
                    <Image src="/pages/images/home/team-member-utility.webp" fluid>

                    </Image>
                </Col>
                <Col md={3} xs={6}>
                    <Image src="/pages/images/home/pit-crew-utility.webp" fluid>

                    </Image>
                </Col>
                <Col md={3} xs={6}>
                    <Image src="/pages/images/home/crew-chief-utility.webp" fluid>

                    </Image>
                </Col >
                <Col md={3} xs={6}>
                    <Image src="/pages/images/home/race-director-utility.webp" fluid>

                    </Image>
                </Col>
            </Row>

            <Row className='pb-5 pt-5'>

            </Row>
            <Row>
                <Col align="center">
                    <Button disabled={dis_state} style={{ textDecoration: "none", lineHeight: "30px" }} href="#VoucherPurchase" align="center" variant="dark">Get your voucher</Button>
                </Col>
            </Row>
            <Row className='pb-5 pt-5'>

            </Row>
        </Container>


    )
}