import { Row, Col, Image, Container } from "react-bootstrap";

export function RoleCarouselItems({props}) {
    const bgColours = {
        "founder": "#272727",
        "race director": "#EFE4F1",
        "crew chief": "#F3BDA1",
        "pit crew": "#AFD6FA",
        "team member": "#B2DFBE"
    }
    const titleColours = {
        "founder": "#D7B340",
        "race director": "#000",
        "crew chief": "#000",
        "pit crew": "#000",
        "team member": "#000"
    }
    const textColours = {
        "founder": "#fff",
        "race director": "#000",
        "crew chief": "#000",
        "pit crew": "#000",
        "team member": "#000"
    }
    return(
        <Container style={{backgroundColor: bgColours[props.name], borderRadius: "16px"}} className="p-4">
                    <Row className="my-3">
                        <Col xs='12' lg='7' className="ml-lg-5 pl-lg-5" >
                            <div className='sectionHeading m-0' style={{ textAlign: 'left', color: titleColours[props.name]}}>{props.title}</div>
                            <p className='pb-3' style={{ textAlign: 'left', fontFamily: 'inter', fontSize: '16px', color: textColours[props.name]}}>{props.bio}</p>
                            <div >
                                <ul className='pt-0' style={{color: textColours[props.name]}}>
                                    {props.rewards.map((reward) =>
                                    <li>
                                    <div className="text-left">{reward}</div>
                                    </li>)}
                                </ul>
                            </div>
                        </Col>
                        <Col xs='12' lg='4' className="d-flex justify-content-center">
                            <Image src='/pages/images/home/RoleCarousel/Pattern-behind-avatar.webp' fluid style={{position: "relative"}}/>
                            {props.name === "founder" ?
                            <Image style={{position: "absolute"}} src={props.imageMF} className=""/>
                            :
                            
                            <Image style={{position: "absolute"}} src={props.image} fluid className=""/>
                            }
                        </Col>
                    </Row>
                    </Container>
    )
}